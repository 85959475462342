import {
  CrmBaseTransformer,
  CrmEndpointListResponse,
} from 'common-module/endpoint';

import { UserModel } from './user.model';

export class UserResponseTransformer extends CrmBaseTransformer<
  CrmEndpointListResponse<UserModel> | UserModel[] | UserModel
> {
  override transform(
    data: CrmEndpointListResponse<UserModel> | UserModel[] | UserModel
  ): CrmEndpointListResponse<UserModel> | UserModel[] | UserModel {
    if (data instanceof Array) {
      return data.map(transform);
    }

    if ('total' in data && 'data' in data) {
      return { ...data, data: data.data.map(transform) };
    }

    return transform(data);
  }
}

const transform = (value: any): UserModel => {
  let { email } = value;

  const { meta = {} } = value;

  if (email && email.startsWith('#')) {
    email = undefined;
  }

  let { category } = meta;

  if (typeof category === 'string') {
    if (category.length === 0) {
      category = null;
    } else {
      category = [category];
    }
  } else if (category && typeof category === 'object') {
    category = Object.values(category);
  }

  return {
    ...value,
    email,
    meta: {
      ...meta,
      category,
    },
  };
};
