export class LocalStorage {
  public static save<T>(key: string, data: T): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public static get<T>(key: string): T | undefined {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : undefined;
  }

  public static remove(key: string): void {
    localStorage.removeItem(key);
  }
}

export enum LocalStorageKeys {
  TOKEN = 'herriot_token',
}
