import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
} from '@angular/core';
import { CrmModalRefDirective } from 'common-module/modal';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { UserApiService } from '~/api/user/user-api.service';

@Component({
  standalone: true,
  selector: 'app-wrong-domain',
  template: `
    <ng-container *nzModalTitle>
      {{ 'wrongDomain.title' | crmTranslate }}
    </ng-container>

    <div class="wrong-domain__message">
      {{ 'wrongDomain.message' | crmTranslate }}
    </div>
    <div class="wrong-domain__buttons">
      <button nz-button nzBlock nzType="primary" (click)="redirect()">
        {{
          { message: 'wrongDomain.redirect', context: { value: data.clinic } }
            | crmTranslate
        }}
      </button>
      <div class="mb-1"></div>
      <button nz-button nzBlock nzType="default" (click)="login()">
        {{
          { message: 'wrongDomain.login', context: { value: data.current } }
            | crmTranslate
        }}
      </button>
    </div>
  `,
  styles: `
    .wrong-domain__message {
      text-align: center;
      margin-bottom: 20px;
    }
  `,
  imports: [NzButtonModule, CrmTranslatePipe, NzModalModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrongDomainComponent extends CrmModalRefDirective<
  void,
  { current: string; clinic: string }
> {
  @HostBinding('class')
  protected readonly hostClass = 'wrong-domain';

  private user = inject(UserApiService);

  protected redirect(): void {
    window.location.assign(`${this.data.clinic}${location.pathname}`);
  }

  protected login(): void {
    this.user.logout().subscribe(() => this.dismiss());
  }
}
