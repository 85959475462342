import { Environment } from './environment.model';
import { APP_PROJECT_NAME, APP_VERSION } from './variables';

export const environment: Environment = {
  production: false,
  env: 'dev',
  version: APP_VERSION,
  projectName: APP_PROJECT_NAME,
  crmUrl: 'https://api.docut.touch4dev.net',
  crmAuth: 'Basic ZG9jdXQ6VDREc3RUdENMbVBoSnI2TDZrNQ==',
};
