import { omit } from 'lodash-es';
import { v4 as uuid4 } from 'uuid';

export class Utils {
  public static UUID(): string {
    return uuid4();
  }

  public static omit(object: any, value: any): any {
    return omit(object, value);
  }
}
