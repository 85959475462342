import { Platform } from '@angular/cdk/platform';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CrmDictionary } from 'common-module/core/types';
import { intervalToDuration } from 'date-fns';
import { omit } from 'lodash-es';
import { fromEvent } from 'rxjs';

import { environment } from '~/environments/environment';

import { stringifyCircularJSON } from '../../../utils/object/stringify';
import { Utils } from '../../../utils/utils';
import {
  BaseEvent,
  ErrorAction,
  ErrorEvent,
  EventBrowser,
  HttpPerformanceEvent,
  HttpPerformanceRequest,
} from '../model/error.event';

const LOCAL_DATA_KEY = '_crm-tracking-local-data';

interface LocalData {
  userId?: string;
  userLoggedAt?: string;
  sessionId: string;
}

@Injectable()
export class ErrorTrackingBuilderService {
  protected localData!: LocalData;
  protected upTimeStart!: Date;

  protected actions: ErrorAction[][] = [];

  private platform = inject(Platform);
  private router = inject(Router);

  public init(): void {
    fromEvent(window, 'click').subscribe((event) => {
      const path = event?.composedPath?.() ?? [];
      const mappedPath = path.slice(0, 5).map((target: EventTarget, index) => {
        const el = target as HTMLElement;

        const mapped: ErrorAction = {
          tagName: el.tagName?.toLowerCase(),
          className: el.className,
        };
        if (index === 0) {
          mapped.text = el.innerText;
        }
        return mapped;
      });
      this.actions.unshift(mappedPath);
      this.actions = this.actions.slice(0, 5);
    });

    this.upTimeStart = new Date();
    const localData = localStorage.getItem(LOCAL_DATA_KEY);
    const parsedLocalData: LocalData = localData
      ? JSON.parse(localData)
      : { sessionId: Utils.UUID() };

    if (!parsedLocalData.userId || !parsedLocalData.userLoggedAt) {
      const legacyLocalMeta = localStorage.getItem('_crm-error-meta');
      if (legacyLocalMeta) {
        const parsedLegacyLocalMeta: { lastLogin: string; user: string } =
          JSON.parse(legacyLocalMeta);
        if (parsedLegacyLocalMeta.user) {
          parsedLocalData.userId = parsedLegacyLocalMeta.user;
        }
        if (parsedLegacyLocalMeta.lastLogin) {
          parsedLocalData.userLoggedAt = parsedLegacyLocalMeta.lastLogin;
        }
      }
    }

    this.setLocalData(parsedLocalData);
  }

  public buildErrorEvent(data: { error: Error }): ErrorEvent {
    const { error } = data;
    const { app, user, session, browser, createdAt } = this.getEventBase();
    return {
      app,
      user,
      session,
      browser,
      createdAt,
      error: JSON.parse(stringifyCircularJSON(error)),
      actions: this.actions,
    };
  }

  public buildHttpPerformanceEvent(data: {
    request: HttpPerformanceRequest;
  }): HttpPerformanceEvent {
    const { request } = data;
    const { app, user, session, browser, createdAt } = this.getEventBase();
    return {
      app,
      user,
      session,
      browser,
      createdAt,
      request,
    };
  }

  public setUserLocalData(data: { id: string; loggedAt: Date }): void {
    const { id, loggedAt } = data;
    this.setLocalData({
      ...(this.localData ?? {}),
      userId: id,
      userLoggedAt: loggedAt.toISOString(),
    });
  }

  public removeUserLocalData(): void {
    this.setLocalData(omit(this.localData, ['userId', 'userLoggedAt']));
  }

  protected getBrowserInfo(): EventBrowser {
    const mapPlatform = () => {
      return Object.entries({
        android: this.platform.ANDROID,
        blink: this.platform.BLINK,
        edge: this.platform.EDGE,
        firefox: this.platform.FIREFOX,
        ios: this.platform.IOS,
        safari: this.platform.SAFARI,
        trident: this.platform.TRIDENT,
        webkit: this.platform.WEBKIT,
      }).reduce(
        (result, [key, value]) => {
          if (value) {
            result[key] = true;
          }
          return result;
        },
        { isBrowser: this.platform.isBrowser } as CrmDictionary,
      );
    };

    return {
      platform: mapPlatform(),
      userAgent: navigator.userAgent,
    };
  }

  protected getEventBase(): BaseEvent {
    const {
      projectName: name = 'Docut',
      env = 'dev',
      version = '0.0.0',
    } = environment;
    return {
      app: {
        name,
        version,
        env,
        state: {
          route: this.router.url,
        },
      },
      user: {
        id: this.localData.userId!,
        loggedAt: this.localData.userLoggedAt!,
      },
      session: {
        id: this.localData.sessionId,
        duration: intervalToDuration({
          start: this.upTimeStart,
          end: new Date(),
        }),
        domain: `${location.protocol}//${location.hostname}`,
      },
      browser: this.getBrowserInfo(),
      createdAt: new Date().toISOString(),
    };
  }

  protected setLocalData(data: LocalData): void {
    this.localData = data;
    localStorage.setItem(LOCAL_DATA_KEY, JSON.stringify(data));
  }
}
