import { CrmDictionary } from 'common-module/core/types';

export const createFormData = (params?: CrmDictionary): FormData => {
  const fd = new FormData();

  if (!params) {
    return fd;
  }

  Object.keys(params).forEach((key) => {
    if (params[key] instanceof Array) {
      (params[key] as []).forEach((val) => {
        fd.append(key, val);
      });
    } else {
      fd.append(key, params[key]);
    }
  });

  return fd;
};
