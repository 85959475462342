import { inject, Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { CrmModalService } from 'common-module/modal';
import { map, Observable, of, tap } from 'rxjs';

import { environment } from '~/environments/environment';
import { WrongDomainComponent } from '~/shared/modal/domain/wrong-domain.component';

import { DocumentsApiService } from '../documents/documents-api.service';

import { ClinicBillingTemplate, ClinicModel } from './clinic.model';
import { CreateClinicDto } from './create-clinic.dto';
import { UpdateClinicDto } from './update-clinic.dto';

@Injectable({ providedIn: 'root' })
export class ClinicsApiService {
  static clinic?: ClinicModel;

  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'clinics',
  })
  private readonly endpoint!: CrmEndpoint<ClinicModel>;

  private documentsApiService = inject(DocumentsApiService);
  private modal = inject(CrmModalService);

  static getClinicBillingTemplate = (): ClinicBillingTemplate => {
    return ClinicsApiService.clinic?.meta?.billing?.template ?? 'sk.unrounded';
  };

  getProfile(): Observable<ClinicModel> {
    if (ClinicsApiService.clinic) {
      return of(ClinicsApiService.clinic);
    }

    return this.endpoint.request<ClinicModel>('GET', 'profile').pipe(
      tap((clinic) => {
        ClinicsApiService.clinic = clinic;
      }),
      tap((clinic) => this.checkDomain(clinic)),
    );
  }

  getClinic(id: string) {
    return this.endpoint.read(id);
  }

  listClinics(params?: CrmDictionary) {
    return this.endpoint.list({ params });
  }

  createClinic(body?: CreateClinicDto) {
    return this.endpoint.create(body);
  }

  updateProfile(body: UpdateClinicDto): Observable<ClinicModel> {
    return this.endpoint.request<ClinicModel>('POST', 'profile', { body }).pipe(
      tap((clinic) => {
        ClinicsApiService.clinic = clinic;
      }),
    );
  }

  updateClinic(id: string, body: UpdateClinicDto): Observable<ClinicModel> {
    return this.endpoint.update(id, body).pipe(
      tap((clinic) => {
        if (clinic._id === ClinicsApiService.clinic?._id) {
          ClinicsApiService.clinic = clinic;
        }
      }),
    );
  }

  getLogoUrl(clinic?: ClinicModel): Observable<string> {
    const logoUrl = (c: ClinicModel) => {
      if (!c.meta?.logo) {
        return '/assets/images/logo-vets-default.svg';
      }

      return this.documentsApiService.getResourceUrl(c.meta.logo);
    };

    if (clinic) {
      return of(logoUrl(clinic));
    }

    return this.getProfile().pipe(map((c) => logoUrl(c)));
  }

  private checkDomain(clinic: ClinicModel) {
    if (clinic.meta.domain !== (environment.domain ?? location.hostname)) {
      this.modal.create({
        nzContent: WrongDomainComponent,
        nzData: {
          clinic: `${location.protocol}//${clinic.meta.domain}`,
          current: `${location.protocol}//${location.hostname}`,
        },
        nzClosable: false,
        nzMaskClosable: false,
        nzFooter: null,
      });
    }
  }
}
