import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DefaultTitleStrategy } from '@angular/router';
import { CrmConfigService } from 'common-module/core';
import { compact } from 'lodash-es';

import { Environment } from '~/environments/environment.model';

@Injectable({ providedIn: 'root' })
export class TitleService extends DefaultTitleStrategy {
  private environment =
    inject(CrmConfigService).getConfig<Environment>('environment');

  private clinicName?: string;

  setClinicName(name: string) {
    this.clinicName = name;
  }

  override getResolvedTitleForRoute(snapshot: ActivatedRouteSnapshot): string {
    const name = this.environment.projectName ?? 'DOCUT';
    const env = this.environment.env ?? 'DEV';
    const prod = this.environment.production ?? false;

    const segments = [
      super.getResolvedTitleForRoute(snapshot),
      this.clinicName,
      name + (prod ? null : `-${env.toUpperCase()}`),
      'PMS',
    ];

    return compact(segments).join(' | ');
  }
}
