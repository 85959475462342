import { Injectable } from '@angular/core';
import { filter, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { v4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class BroadcastChannelService {
  private session = v4();
  private channel: BroadcastChannel;

  private emitter$ = new Subject<{ type: string; session: string }>();

  constructor() {
    this.channel = new BroadcastChannel(
      `__docut_channel__${location.hostname}__`,
    );
    this.channel.onmessage = (event) => this.emitter$.next(event.data);
  }

  subscribe(type: string) {
    return this.emitter$.pipe(
      filter((event) => event.type === type && event.session !== this.session),
      debounceTime(0),
    );
  }

  post(type: string) {
    this.channel.postMessage({ type, session: this.session });
  }
}
